import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";
import textHelper from "../../helpers/textHelper";
import printJS from "print-js";
import axios from "./../../plugins/axios";

export default {
    namespaced: true,
    state: () => ({
        forms: [],
        routes: {},
        currentPerimissions: {
            canCreate: false,
            canEdit: false,
            canPrint: false,
            canRfid: false,
            canToppanel: false
        },
        blackList: null
    }),
    mutations: {
        SET_FORMS: (state, value) => state.forms = value,
        SET_ROUTES: (state, value) => state.routes = value,
        SET_PERMISSIONS: (state, value) => state.currentPerimissions = value,
        SET_BLACKLIST: (state, value) => state.blackList = value
    },
    actions: {
        init({dispatch, commit}, forms) {
            let data = forms?.data || [];
            commit('SET_FORMS', data);
            commit('SET_ROUTES', ROUTES.MERCHANT.SYSTEM)
            dispatch('pagination/create', forms, {root: true});
        },
        initBlacklist({commit}, values) {
            let lowercaseValues = values.map(item => textHelper.lowercase(item));
            commit('SET_BLACKLIST', lowercaseValues)
        },
        setPermissions({commit}, permissions) {
            let defaultPerimissions = {
                canCreate: false,
                canRfid: false,
                canPrint: false,
                canEdit: false,
                canToppanel: false
            }
            for (let key in permissions) {
                if (permissions.hasOwnProperty(key) && defaultPerimissions.hasOwnProperty(key)) {
                    defaultPerimissions[key] = permissions[key];
                }
            }

            commit('SET_PERMISSIONS', defaultPerimissions)
        },
        destroy({dispatch, commit}) {
            commit('SET_FORMS', []);
            commit('SET_ROUTES', {});
            commit('SET_PERMISSIONS', {
                canCreate: false,
                canRfid: false,
                canPrint: false,
                canEdit: false,
                canToppanel: false
            })
            dispatch('pagination/destroy', [], {root: true});
        },
        onSearch({state}, search) {
            if (!state.routes.hasOwnProperty('INDEX')) {
                return;
            }
            router.get(state.routes.INDEX, search, {
                only: ['search', 'state', 'type', 'forms']
            })
        },
        onResetSearch({state}) {
            if (!state.routes.hasOwnProperty('INDEX')) {
                return;
            }
            let url = state.routes.INDEX;
            router.get(url, {}, {
                only: ['search', 'state', 'type', 'forms']
            })
        },
        changeState({dispatch, state}, {ids = [], newState = 'approved'}) {
            return new Promise((resolve, reject) => {
                dispatch('general/setActionState', true, { root:true})

                let data = {
                    state: newState,
                    ids: ids
                }

                router.post(ROUTES.MERCHANT.SYSTEM.STATE, data, {
                    onSuccess: page => {
                        dispatch('init', page.props.forms);
                        resolve()
                    },
                    onFinish: () => {
                        dispatch('general/setActionState', false, {root: true});
                    }
                })
            })
        },
        onPrint({dispatch, state, commit, getters}, ids) {
            if (!Array.isArray(ids)) {
                ids = new Array(ids);
            }

            let activeIds = getters.getValidIds(ids);

            dispatch('general/setActionState', true, {root: true});

            const pdfUrl = getters.getPdfURL(activeIds, true);

            return new Promise((resolve, reject) => {
                if (!activeIds.length) {
                    console.log("Not Valid for Print")
                    resolve()
                }
                printJS({
                    printable: pdfUrl,
                    type: 'pdf',
                    onPrintDialogClose: () => {
                        dispatch('general/setActionState', false, {root: true});
                        router.reload({
                            only: ['forms'],
                            onSuccess: (page) => {
                                dispatch('init', page.props.forms)
                            },
                            onFinish: () => {
                                resolve()
                            }
                        })
                    },
                    onError: () => {
                        dispatch('general/setActionState', false, {root: true});
                        reject()
                    }
                })
            })
        },
        onSubmitRFID({dispatch}, {form, id}) {
            dispatch('general/setActionState', true, {root: true})
            let route = ROUTES.MERCHANT.SYSTEM.RFDID;
            route = route.replace('@', id);
            router.post(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root: true})
                }
            })
        },
        async prepareCartData({state, getters}, ids) {
            if (!Array.isArray(ids)) {
                ids = new Array(ids);
            }
            const pdfUrl = getters.getPdfURL(ids);

            return axios.get(pdfUrl)
        }
    },
    getters: {
        getForms: (state) => state.forms || [],
        getValidIds: (state) => (ids) => {
            let validIds = [];

            state.forms.forEach(function (form, index) {
                if (ids.indexOf(form.idForm) > -1 && form.state === 'approved') {
                    validIds.push(form.idForm);
                }
            });

            return validIds;
        },
        getPdfURL: () => (ids, print = false) => {
            let params = [];
            const route = ROUTES.MERCHANT.SYSTEM.PDF;

            if (Array.isArray(ids)) {
                params.push('ids=' + ids.join(','));
            } else {
                params.push('ids=' + ids)
            }

            if (print) {
                params.push('print=true');
            }

            return route + '?' + params.join('&');
        },
        getEditUrl: () => (id) => {
            let route = ROUTES.MERCHANT.SYSTEM.EDIT;
            return route.toString().replace('@', id);
        },
        getRFIDUrl: () => (id) => {
            let route = ROUTES.MERCHANT.SYSTEM.RFDID;
            return route.toString().replace('@', id);
        },
        getIndexUrl: () => ROUTES.MERCHANT.SYSTEM.INDEX,
        getCreateUrl: () => ROUTES.MERCHANT.SYSTEM.CREATE,
        can: (state) => (permission) => {
            permission = textHelper.capitalLowercase(permission)
            if (['Create', 'Edit', 'Print', 'Rfid', 'Toppanel'].indexOf(permission) === -1) {
                return false;
            }
            let permissionName = 'can' + permission;
            return state.currentPerimissions.hasOwnProperty(permissionName) && state.currentPerimissions[permissionName] === true
        },
        isPrinted: () => (form) => {
            if (!form) {
                return false;
            }

            let result = false;
            if (form.hasOwnProperty('logs') && form.logs.length > 0) {
                for (let logsKey in form.logs) {
                    if (form.logs.hasOwnProperty(logsKey)) {
                        let type = form.logs[logsKey].type.toString().toLowerCase()
                        if (type === 'print') {
                            result = true;
                            break;
                        }
                    }
                }
            }
            return result;
        },
        isCollected: () => (form) => {
            if (!form) {
                return false;
            }

            let result = false;
            if (form.hasOwnProperty('logs') && form.logs.length > 0) {
                for (let logsKey in form.logs) {
                    if (form.logs.hasOwnProperty(logsKey)) {
                        let type = form.logs[logsKey].type.toString().toLowerCase()
                        if (type === 'Módulo firma'.toLowerCase()) {
                            result = true;
                            break;
                        }
                    }
                }
            }
            return result;
        },
        isInBlackList: (state) => (form) => {
            if (state.blackList && state.blackList.length) {
                let fullname = textHelper.lowercase(form.name) + ' ' + textHelper.lowercase(form.lastName)
                return state.blackList.indexOf(fullname) !== -1;
            }
            return false;
        },
        hasRestaurant: () => (form) => {
            return form?.restaurants?.length > 0
        }
    },
}
