export default {
    namespaced: true,
    state: () => ({
        links: [],
        currentPage: 1,
        lastPage: 1,
        nextUrl: '',
        prevUrl: ''
    }),
    mutations: {
        SET_LINKS: (state, value) => state.links = value,
        SET_LASTPAGE: (state, value) => state.lastPage = value,
        SET_CURRENTPAGE: (state, value) => state.currentPage = value,
        SET_NEXTURL: (state, value) => state.nextUrl = value,
        SET_PREVURL: (state, value) => state.prevUrl = value
    },
    actions: {
        create({commit}, value = []) {
            let links = value?.links || [];
            let currentPage = value?.current_page || 1;
            let lastPage = value?.last_page || 1;
            let nextUrl = value?.next_page_url || '';
            let prevUrl = value?.prev_page_url || '';
            commit('SET_LINKS', links);
            commit('SET_CURRENTPAGE', currentPage)
            commit('SET_LASTPAGE', lastPage)
            commit('SET_NEXTURL', nextUrl)
            commit('SET_PREVURL', prevUrl)
        },
        destroy({commit}) {
            commit("SET_LINKS", []);
            commit("SET_LASTPAGE", 1);
            commit("SET_CURRENTPAGE", 1);
            commit("SET_NEXTURL", '');
            commit("SET_PREVURL", '');
        }
    },
    getters: {
        hasPagination: (state) => state.lastPage > 1,
        getLinks:(state) => state.links || [],
        getCurrentPage: (state) => state.currentPage,
        getLastPage: (state) => state.lastPage,
        isFirstPage: (state) => state.currentPage === 1,
        isLastPage: (state) => state.currentPage >= state.lastPage,
        getNextUrl: (state) => state.nextUrl || '#',
        getPrevUrl: (state) => state.prevUrl || '#'
    },
}
