import i18n from "../../plugins/i18n";
import { router } from '@inertiajs/vue3'
import textHelper from "../../helpers/textHelper";
const LOCALES = ['es', 'en'];

export default {
    namespaced: true,
    state: () => ({
        locale: 'es',
        app: [],
        flash: null,
        user: null,
        actionState: false,
        modalState: false,
        navbar: [],
        langSelector: true,
        showNavbar: true
    }),
    mutations: {
        SET_LOCALE: (state, value) => state.locale = value,
        SET_APP: (state, value) => state.app = value,
        SET_FLASH: (state, value) => state.flash = value,
        SET_USER: (state, value) => state.user = value,
        SET_ACTIONSTATE: (state, value) => state.actionState = !!value,
        SET_MODALSTATE: (state, value) => state.modalState = value,
        SET_NAVBAR: (state, value) => state.navbar = value,
        SET_LANGSELECTOR: (state, value) => state.langSelector = value,
        SET_SHOWNAVBAR: (state, value) => state.showNavbar = value
    },
    actions: {
        setLocale({state, commit}, locale) {
            const newLocale = textHelper.lowercase(locale);
            const locales = state.app.locales || LOCALES;
            if (locales.indexOf(newLocale) > -1) {
                commit('SET_LOCALE', newLocale);
                i18n.global.locale = newLocale
            }
        },
        setApp({commit}, app = []) {
            commit("SET_APP", app)
        },
        setFlash({commit}, value) {
            commit('SET_FLASH', value)
        },
        setUser({commit}, value = null) {
            commit('SET_USER', value);
        },
        setNavbar({commit}, value) {
            commit('SET_NAVBAR', value)
        },
        setActionState({commit}, value) {
            commit("SET_ACTIONSTATE", value)
        },
        setLangSelector({commit}, value) {
            commit('SET_LANGSELECTOR', value);
        },
        changeLocale({state, commit}, locale) {
            const newLocale = textHelper.lowercase(locale);
            const locales = state.app.locales || LOCALES;
            if (locales.indexOf(newLocale) > -1) {
                router.get(`/lang/${newLocale}`, {}, {
                    only: ['locale', 'app'],
                    onFinish: (page) => {
                        commit('SET_LOCALE', newLocale)
                        i18n.global.locale = newLocale
                    }
                })
            }
        },
        showModal({commit}) {
            commit('SET_MODALSTATE', true);
        },
        hideModal({commit}) {
            commit('SET_MODALSTATE', false);
        },
        closeFlash({commit}) {
            commit('SET_FLASH', null);
        },
        showTheNavbar({commit}) {
            commit('SET_SHOWNAVBAR', true)
        },
        hideTheNavbar({commit}) {
            commit('SET_SHOWNAVBAR', false)
        }
    },
    getters: {
        getAppName: (state) => state.app?.name || "Acreditacion",
        getCurrentLocale: (state) => state.locale || 'es',
        getOtherLocales: (state) => {
            let locales = [...state.app.locales]

            if (Array.isArray(locales) && locales.indexOf(state.locale) > -1) {
                let index = locales.indexOf(state.locale);
                locales.splice(index, 1);
            }
            return locales;
        },
        getActionState: (state) => !!state.actionState,
        getMenuItems: (state) => state.navbar || [], // filter data before export
        hasFlash: (state) => {
            return state.flash && state.flash.type && state.flash.message;
        },
        getFlashType: (state) => {
            if (!state.flash) {
                return null
            }
            return state.flash.type;
        },
        getFlashMessage: (state) => {
            if (!state.flash) {
                return null
            }
            return state.flash.message;
        },
        getModalState: (state) => !!state.modalState,
        getLangSelector: (state) => !!state.langSelector,
        isShowNavbar: (state) => state.showNavbar && state.user
    },
}
