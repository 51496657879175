import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";

export default {
    namespaced: true,
    actions: {
        updatePermission({dispatch}, {form, pId}){
            dispatch('general/setActionState', true, { root:true})

            let route = ROUTES.MERCHANT.PERMISSIONS.UPDATE;
            route = route.replace('@', pId);

            router.put(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        }
    },
    getters: {
        getEditUrl: () => (id) => {
            let route = ROUTES.MERCHANT.PERMISSIONS.EDIT;
            return route.toString().replace('@', id);
        },
        getActivePermissions:(state, getters) => (permissions) => {
            let data = [];
            for (const permissionsKey in permissions) {
                if(permissions.hasOwnProperty(permissionsKey)) {
                    let key = permissionsKey.toString().toLowerCase();
                    let value = permissions[permissionsKey].toString();
                    if (getters.isBoolean(value)) {
                        if(value !== '0'){
                            data.push(key);
                        }
                    } else {
                        data.push(`${key}: ${value}`)
                    }
                }
            }

            return data;
        },
        isBoolean: () => (value) => ['0', '1'].indexOf(value.toString()) > -1
    },
}
