import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";

const FORMTYPES = [
    'ballboy',
    'player',
    'press',
    'line',
    'volunteer',
    'accreditation',
    'golfvolunteer',
    'golfaccreditation',
    'golfpress'
];
const SIGNEDTYPES = ['guest'];
export default {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    actions: {
        showModal({dispatch}) {
            dispatch('general/showModal', null, {root: true})
        },
        hideModal({dispatch}){
            dispatch('general/hideModal', null, {root: true})
        },
        submitForm({dispatch}, {data, formType='ballboy'}){
            let currentFormType = formType.toString().toLowerCase();
            if (FORMTYPES.indexOf(currentFormType) === -1) {
               return 0;
            }

            let route = ROUTES.PUBLIC[currentFormType.toUpperCase()];

            if(['accreditation','golfaccreditation'].indexOf(formType) > -1 && data.type) {
                route = route.toString().replace('@', data.type);
            }

            dispatch('general/setActionState', true, { root:true})
            router.post(route, data, {
                forceFormData: formType === 'line',
                onFinish: () => {
                    dispatch('hideModal');
                    dispatch('general/setActionState', false, { root:true});
                    setTimeout(()=>{
                        window.scrollTo(0,0)
                    },100);
                }
            })
        },
        submitOldPlayerForm({dispatch}, {data, formType = 'player'}) {
            let currentFormType = formType.toString().toLowerCase();
            if (FORMTYPES.indexOf(currentFormType) === -1) {
                return 0;
            }

            let route = ROUTES.PUBLIC.OLDPLAYER;

            dispatch('general/setActionState', true, { root:true})
            router.post(route, data, {
                onFinish: () => {
                    dispatch('hideModal');
                    dispatch('general/setActionState', false, { root:true});
                    setTimeout(()=>{
                        window.scrollTo(0,0)
                    },100);
                }
            })
        },
        submitSignedForm({dispatch}, {url, data, formType = 'guest'}){
            let currentFormType = formType.toString().toLowerCase();
            if (SIGNEDTYPES.indexOf(currentFormType) === -1) {
               return 0;
            }

            dispatch('general/setActionState', true, { root:true})
            router.post(url, data, {
                onFinish: () => {
                    dispatch('hideModal');
                    dispatch('general/setActionState', false, { root:true});
                    setTimeout(()=>{
                        window.scrollTo(0,0)
                    },100);
                }
            })
        }
    },
    getters: {},
}
