import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";
import searchHelper from "../../helpers/search";

export default {
    namespaced: true,
    state: () => ({
        players: []
    }),
    mutations: {
        SET_PLAYERS: (state, value) => state.players = value
    },
    actions: {
        init({dispatch, commit}, players) {
            let data = players?.data || [];
            commit('SET_PLAYERS', data);
            dispatch('pagination/create', players, {root: true});
        },
        destroy({dispatch, commit}){
            commit('SET_PLAYERS', []);
            dispatch('pagination/destroy', [], {root: true});
        },
        onSearch({}, search){
            let route = ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.INDEX + "?" + searchHelper.getSearchQuery(search);
            router.get(route, {} , {
                only: ['search', 'players']
            })
        },
        onResetSearch({}){
            let url = ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.INDEX;
            router.get(url, {}, {
                only: ['search', 'players']
            })
        },
        createPlayer({dispatch}, form){
            dispatch('general/setActionState', true, { root:true})
            router.post(ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.STORE, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        updatePlayer({dispatch}, {form, id}){
            dispatch('general/setActionState', true, { root:true})

            let route = ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.UPDATE;
            route = route.replace('@', id);

            router.put(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        deletePlayer({dispatch}, id){
            dispatch('general/setActionState', true, { root:true})

            let route = ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.DELETE;
            route = route.replace('@', id);

            router.delete(route, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        }
    },
    getters: {
        getPlayers: (state) => state.players || [],
        getEditUrl: () => (id) => {
            let route = ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.EDIT;
            return route.toString().replace('@', id);
        },
        getIndexUrl: () => ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.INDEX,
        getCreateUrl: () => ROUTES.MERCHANT.CONFIG.DEFAULTPLAYER.CREATE
    },
}
