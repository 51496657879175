import axios from 'axios'

const instance = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type' : 'application/json'
    }
})
export default instance;

