import ROUTES from "../../helpers/routes";
import axios from "../../plugins/axios.js";

export default {
    namespaced: true,
    state: () => ({
        routes: ROUTES.MERCHANT.SKIDATA
    }),
    actions:{
        addForm({dispatch, state}, idForm) {
            dispatch('general/setActionState', true, { root:true});
            let route = state.routes.ADD;
            route = route.replace('@', idForm);
            axios
                .post(route, {})
                .finally(() => {
                    dispatch('general/setActionState', false, { root:true});
                })
            ;
        },
        blockForm({dispatch, state}, idForm) {
            dispatch('general/setActionState', true, { root:true});
            let route = state.routes.BLOCK;
            route = route.replace('@', idForm);
            axios
                .post(route, null)
                .finally(() => {
                    dispatch('general/setActionState', false, { root:true});
                })
            ;
        },
        unblockForm({dispatch, state}, idForm) {
            dispatch('general/setActionState', true, { root:true});
            let route = state.routes.UNBLOCK;
            route = route.replace('@', idForm);
            axios
                .post(route, null)
                .finally(() => {
                    dispatch('general/setActionState', false, { root:true});
                })
            ;
        }
    }
}
