/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import store from './store/index.js'
import i18n from "./plugins/i18n.js";
import CleanLayout from "./Layouts/CleanLayout.vue";
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import '../sass/app.scss'

createInertiaApp({
    progress: {
        delay: 100,
        color: '#29d'
    },
    resolve: name => {
        const page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
        page.layout = page.layout || CleanLayout;
        return page;
    },
    setup({el, App, props, plugin}) {
        const pageProps = props?.initialPage?.props;
        const locale = pageProps?.locale || 'es';

        // push auth data to vuex
        store.state.general.app = pageProps?.app;
        store.state.general.navbar = pageProps?.navbar;
        store.state.general.user = pageProps?.user;
        store.state.general.flash = pageProps?.flash;
        store.state.general.locale = locale;
        store.state.general.langSelector = true;
        i18n.global.locale = locale;

        createApp({render: () => h(App, props)})
            .use(plugin)
            .use(store)
            .use(i18n)
            .mount(el)
    }
})

