<template>
    <head>
        <title>{{ title ? title : getAppName}}</title>
    </head>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import {mapGetters} from "vuex";

export default {
    name: "metaTitle",
    props: {
        title: String
    },
    computed: mapGetters('general', ['getAppName']),
    components: {
        Head
    }
}
</script>

<style scoped>

</style>
