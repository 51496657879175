import ROUTES from "../../helpers/routes";
import { router } from "@inertiajs/vue3";
import axios from "../../plugins/axios";

const OnlyUpdateItems = ['search', 'collectives']

export default {
    namespaced: true,
    state: () => ({
        collectives: [],
        routes: ROUTES.MERCHANT.COLLECTIVE,
        validatedForms: []
    }),
    mutations: {
        SET_COLLECTIVES: (state, value) => state.collectives = value,
        RESET_VALIDATED_FORMS: (state) => state.validatedForms = [],
        ADD_VALIDATED_FORMS: (state, value) => {
            const index = state.validatedForms.findIndex(function (form) {
                return form.uuid === value.uuid
            });
            if (index < 0) {
                state.validatedForms.push(value)
            }
        },
        REMOVE_VALIDATE_FORM: (state, value) => {
            const index = state.validatedForms.findIndex(function (form) {
                return form.uuid === value.uuid
            });
            if (index !== -1) {
                state.validatedForms.splice(index, 1)
            }
        }
    },
    actions: {
        init({dispatch, commit}, collectives) {
            let data = collectives?.data || [];
            commit('SET_COLLECTIVES', data)
            commit('RESET_VALIDATED_FORMS')
            dispatch('pagination/create', collectives, {root: true});
        },
        destroy({dispatch, commit}) {
            commit('SET_COLLECTIVES', [])
            dispatch('pagination/destroy', [], {root: true});
        },
        onSearch({state}, search) {
            if (!state.routes.hasOwnProperty('INDEX')) {
                return;
            }

            router.get(state.routes.INDEX, search, {
                only: OnlyUpdateItems
            })
        },
        onResetSearch({state}) {
            if (!state.routes.hasOwnProperty('INDEX')) {
                return;
            }
            let url = state.routes.INDEX;
            router.get(url, {}, {
                only: OnlyUpdateItems
            })
        },
        create({dispatch, state}, collective) {
            if (!state.routes.hasOwnProperty('STORE')) {
                return;
            }
            dispatch('general/setActionState', true, {root: true})
            router.post(state.routes.STORE, collective, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root: true});
                }
            })
        },
        update({dispatch, state}, {collective, id}) {
            if (!state.routes.hasOwnProperty('UPDATE')) {
                return;
            }
            dispatch('general/setActionState', true, {root: true})

            let route = state.routes.UPDATE;
            route = route.replace('@', id);

            router.put(route, collective, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root: true});
                }
            })
        },
        onValidateCode({dispatch, state, commit, getters}, {uuid, callback}) {
            if (!state.routes.hasOwnProperty('VALIDATE')) {
                return;
            }
            let route = state.routes.VALIDATE;
            route = route.replace('@', uuid);

            dispatch('general/setActionState', true, {root: true})

            axios.post(route, {},)
                .then(response => {
                    if (response.data.form) {
                        commit("ADD_VALIDATED_FORMS", response.data.form)
                        callback(getters.getValidatedFormsIds)
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    dispatch('general/setActionState', false, {root: true});
                })
        },
        setValidateForms({commit}, forms) {
            for (let index in forms) {
                commit('ADD_VALIDATED_FORMS', forms[index])
            }
        },
        removeValidateForm({commit, getters}, {form, callback}) {
            commit("REMOVE_VALIDATE_FORM", form)
            callback(getters.getValidatedFormsIds)
        }
    },
    getters: {
        getCollectives: (state) => state.collectives || [],
        getIndexUrl: (state) => {
            if (!state.routes.hasOwnProperty('INDEX')) {
                return '#'
            }
            return state.routes.INDEX;
        },
        getPreviewUrl: (state) => (id) => {
            if (!state.routes.hasOwnProperty('SHOW')) {
                return '#'
            }
            let route = state.routes.SHOW;
            return route.toString().replace('@', id);
        },
        getEditUrl: (state) => (id) => {
            if (!state.routes.hasOwnProperty('EDIT')) {
                return '#'
            }
            let route = state.routes.EDIT;
            return route.toString().replace('@', id);
        },
        getPrintUrl: (state) => (id) => {
            if (!state.routes.hasOwnProperty('PRINT')) {
                return '#'
            }
            let route = state.routes.PRINT;
            return route.toString().replace('@', id);
        },
        getCreateUrl: (state) => {
            if (!state.routes.hasOwnProperty('CREATE')) {
                return '#'
            }
            return state.routes.CREATE;
        },
        getCollectiveState: (state, getters) => {
            return (collective) => {
                const SIGNED = 'merchant.collective.signed'
                const WAITING = 'merchant.collective.waiting'
                return getters.hasSign(collective) ? SIGNED : WAITING
            }
        },
        hasSign: () => {
            return (collective) => {
                return collective?.sign !== null && collective?.collective_form[0]?.signed_at !== null;
            }
        },
        canEdit: (state, getters) => {
            return (collective) => {
                return !getters.hasSign(collective)
            }
        },
        getValidatedForms: (state) => state.validatedForms,
        getValidatedFormsIds: (state) => {
            let result = [];
            const all = state.validatedForms
            for (const ind in all) {
                const form = all[ind];
                result.push(form.idForm)
            }
            return result;
        }
    }
}
