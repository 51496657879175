<template>
    <meta-title />
    <main class="container-fluid">
        <slot/>
    </main>
</template>

<script>
import metaTitle from "../components/elements/metaTitle.vue";

export default {
    name: "CleanLayout",
    components: {
        metaTitle
    }
}
</script>

<style scoped>
</style>
