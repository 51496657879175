const __getBallboyForm = {
    image: null,
    lastName: null,
    name: null,
    birthDate: null,
    document: null,
    address: null,
    idGender: null,
    phone: null,
    cellPhone: null,
    email: null,
    idPermission: null,
    customPermission: {},
    state: 'waiting',
    credits: 0,
    amount: 0,
    ballboy: {
        license: null,
        workAsBallboy: false,
        workAsBallboyMMO: false,
        workAsBallboyMMOCount: null,
        tennisHabit: false,
        tennisHabitFreq: null,
        polo: null,
        pant: null,
        shoes: null,
        tutorLastName: null,
        tutorName: null,
        tutorDocument: null,
        tutorGender: null,
        tutorPhone: null,
        tutorCellPhone: null,
        tutorEmail: null,
        observation: null,
    }
};
const __getPlayerForm = {
    image: null,
    lastName: null,
    name: null,
    birthDate: null,
    document: null,
    idGender: null,
    cellPhone: null,
    email: null,
    state: 'waiting',
    credits: 0,
    amount: 0,
    idPermission: null,
    customPermission: {},
    has_guest1: false,
    has_guest2: false,
    has_guest3: false,
    player: {
        validity: null,
        passportPhoto: null,
        tour: null
    },
    guests: {}
};
const __getPressForm = {
    image: null,
    name: null,
    lastName: null,
    idGender: null,
    document: null,
    birthDate: null,
    idNationality: null,
    email: null,
    cp: null,
    cellPhone: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting',
    press: {
        validity: null,
        birthCountry: null,
        media: 'magazine',
        mediaName: null,
        freelance: null,
        parking: false,
        days: []
    }
}
const __getLineForm = {
    name: null,
    lastName: null,
    document: null,
    idGender: null,
    city: null,
    idCountry: null,
    cellPhone: null,
    email: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting',
    line: {
        type: 'line',
        availablePeriod: null,
        experience: null,
        polo: null,
        shoes: null,
        pant: null,
        jacket: null
    }
};
const __getVolunteerForm = {
    image: null,
    name: null,
    lastName: null,
    idGender: null,
    birthDate: null,
    email: null,
    address: null,
    city: null,
    cp: null,
    idCountry: null,
    document: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting',
    volunteer: {
        region: null,
        education: null,
        speciality: null,
        language: null,
        level: null,
        otherLanguage: null,
        workExperience: null,
        shift: null,
        previouslyParticipate: false,
        pYear: null,
        pPosition: null,
        polo: null,
        jacket: null,
        pant: null,
        shoes: null,
        responsability: null,
        license: null
    }
};
const __getAccreditationForm = {
    image: null,
    name: null,
    lastName: null,
    birthDate: null,
    idGender: null,
    document: null,
    company: null,
    email: null,
    cellPhone: null,
    address: null,
    city: null,
    cp: null,
    idCountry: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting'
};
const __getFreeForm = {
    image: null,
    name: null,
    lastName: null,
    birthDate: null,
    idGender: null,
    document: null,
    company: null,
    email: null,
    cellPhone: null,
    address: null,
    city: null,
    cp: null,
    idCountry: null,
    idNationality: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting'
};
const __getGuestForm = {
    image: null,
    name: null,
    lastName: null,
    birthDate: null,
    idGender: null,
    document: null,
    company: null,
    email: null,
    cellPhone: null,
    address: null,
    city: null,
    cp: null,
    idCountry: null,
    idNationality: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting',
    idParent: null,
    guest: {
        type: null,
        validity: null
    }
};
const __getGVolunteerForm = {
    image: null,
    name: null,
    lastName: null,
    document: null,
    idGender: null,
    birthDate: null,
    email: null,
    phone: null,
    address: null,
    city: null,
    cp: null,
    idCountry: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting',
    volunteer: {
        drivingLicence: false,
        education: null,
        federated: false,
        federatedLicence: null,
        firstPosition: null,
        golfClubCode: null,
        golfClubSubscription: false,
        jacket: null,
        language: null,
        level: null,
        observation: null,
        otherLanguage: null,
        pPosition: null,
        pYear: null,
        playGolf: false,
        polo: null,
        previouslyParticipate: false,
        region: null,
        secondPosition: null,
        shift: null,
        speciality: null,
        tournamentAvailability: null,
        weYear: null,
        workExperience: null,
        responsability: null
    }
};
const __getGAccreditation = {
    image: null,
    name: null,
    lastName: null,
    birthDate: null,
    idGender: null,
    document: null,
    company: null,
    email: null,
    cellPhone: null,
    idCountry: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting'
};
const __getGPress = {
    image: null,
    name: null,
    lastName: null,
    idGender: null,
    document: null,
    birthDate: null,
    idNationality: null,
    email: null,
    cp: null,
    cellPhone: null,
    idPermission: null,
    customPermission: {},
    credits: 0,
    amount: 0,
    state: 'waiting',
    press: {
        validity: null,
        birthCountry: null,
        media: 'magazine',
        mediaName: null,
        freelance: null,
        days: [],
        observation: null,
        work_table: false
    }
};
export default {
    BallboymForm : __getBallboyForm,
    PlayermForm : __getPlayerForm,
    PressmForm : __getPressForm,
    LinemForm : __getLineForm,
    VolunteermForm : __getVolunteerForm,
    AccreditationmForm : __getAccreditationForm,
    GuestmForm : __getGuestForm,
    FreemForm : __getFreeForm,
    GolfVolunteerForm: __getGVolunteerForm,
    GolfAccreditation: __getGAccreditation,
    GolfPress: __getGPress
}
