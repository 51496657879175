import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";
const types = Object.keys(ROUTES.MERCHANT.FORMS);

export default {
    namespaced: true,
    state: () => ({
        forms: [],
        routes: {},
        type: null
    }),
    mutations: {
        SET_FORMS: (state, value) => state.forms = value,
        SET_ROUTES: (state, value) => state.routes = value,
        SET_TYPE: (state, value) => state.type = value,
    },
    actions: {
        setType({commit}, type) {
            let newType = type.toString().toUpperCase();
            if (types.indexOf(newType) > -1) {
                commit('SET_TYPE', newType)
                commit('SET_ROUTES', ROUTES.MERCHANT.FORMS[newType])
            }
        },
        init({dispatch, commit}, {forms, type}) {
            let data = forms?.data || [];
            commit('SET_FORMS', data);
            dispatch('setType', type);
            dispatch('pagination/create', forms, {root: true});
        },
        destroy({dispatch, commit}){
            commit('SET_FORMS', []);
            commit('SET_ROUTES', {});
            dispatch('pagination/destroy', [], {root: true});
        },
        onSearch({state}, search){
            if (!state.routes.hasOwnProperty('INDEX')) {
                return;
            }

            let onlyUpdate = ['search', 'state', 'forms'];
            if (search.hasOwnProperty('category')) {
                onlyUpdate.push('category');
            }

            router.get(state.routes.INDEX, search , {
                only: onlyUpdate
            })
        },
        onResetSearch({state}){
            if (!state.routes.hasOwnProperty('INDEX')) {
                return;
            }
            let url = state.routes.INDEX;
            router.get(url, {}, {
                only: ['search', 'state', 'forms']
            })
        },
        create({dispatch, state}, form){
            if (!state.routes.hasOwnProperty('STORE')) {
                return;
            }
            dispatch('general/setActionState', true, { root:true})
            router.post(state.routes.STORE, form, {
                forceFormData: state.type === 'LINE',
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        createWithParams({dispatch, state}, {form, id}){
            if (!state.routes.hasOwnProperty('STORE')) {
                return;
            }
            let route = state.routes.STORE.toString().replace('@', id)

            dispatch('general/setActionState', true, { root:true})
            router.post(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        update({dispatch, state}, {form, id}){
            if (!state.routes.hasOwnProperty('UPDATE')) {
                return;
            }
            dispatch('general/setActionState', true, { root:true})

            let route = state.routes.UPDATE;
            route = route.replace('@', id);

            router.put(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        updateWithFile({dispatch, state}, {form, id}){
            if (!state.routes.hasOwnProperty('UPDATE')) {
                return;
            }
            dispatch('general/setActionState', true, { root:true})

            let route = state.routes.UPDATE;
            route = route.replace('@', id);

            let data = {...form, _method: 'put'}

            router.post(route, data, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        changeState({dispatch, state}, {id, newState, type}){
            if (!state.routes.hasOwnProperty('STATE')) {
                return;
            }
            dispatch('general/setActionState', true, { root:true})

            let route = state.routes.STATE;
            route = route.replace('@', id);

            router.post(route, {state: newState}, {
                onSuccess: page => {
                    dispatch('init', {
                        forms: page.props.forms,
                        type: type
                    })
                },
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        export({dispatch, state}, data){
            if (!state.routes.hasOwnProperty('EXPORT')) {
                return;
            }
            dispatch('general/setActionState', true, {root:true});
            router.post(state.routes.EXPORT, data, {
                only: ['flash'],
                onSuccess: () => {
                    dispatch('general/hideModal', false, {root:true});
                },
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        },
        assignGuestToPlayer({dispatch, state}, data) {
            const route = ROUTES.MERCHANT.FORMS.PLAYER.ASSIGN;
            dispatch('general/setActionState', true, {root:true});
            router.post(route, data, {
                only: ['flash'],
                onSuccess: () => {
                    dispatch('general/hideModal', false, {root:true});
                },
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        }
    },
    getters: {
        getForms: (state) => state.forms || [],
        getEditUrl: (state) => (id) => {
            if(!state.routes.hasOwnProperty('EDIT')) {
                return '#'
            }
            let route = state.routes.EDIT;
            return route.toString().replace('@', id);
        },
        getPreviewUrl: (state) => (id) => {
            if(!state.routes.hasOwnProperty('SHOW')) {
                return '#'
            }
            let route = state.routes.SHOW;
            return route.toString().replace('@', id);
        },
        getIndexUrl: (state) => {
            if(!state.routes.hasOwnProperty('INDEX')) {
                return '#'
            }
            return state.routes.INDEX;
        },
        getCreateUrl: (state) => {
            if(!state.routes.hasOwnProperty('CREATE')) {
                return '#'
            }
            return state.routes.CREATE;
        },
        getNewGuestCreateURL: (state) => {
            return (id) => {
                let routes = ROUTES.MERCHANT.FORMS.GUEST
                if (!routes.hasOwnProperty('CREATE')) {
                    return '#';
                }
                return routes.CREATE.toString().replace('@', id);
            }
        },
        serveFile: () => (filePath) => {
            if(filePath == null || filePath.length === 0) {
                return null
            }
            return ROUTES.MERCHANT.SERVEFILE + "?file=" + filePath
        },
        canApprove: () => (state) => {
            return ['waiting', 'rejected'].indexOf(state) > -1
        },
        canReject: () => (state) => {
            return ['waiting', 'approved'].indexOf(state) > -1
        }
    },
}
