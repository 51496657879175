import {createI18n} from 'vue-i18n'
import En from '../../lang/en.json'
import Es from '../../lang/es.json'

export default createI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages: {
        'en' : { ...En },
        'es' : { ...Es }
    }
})
