import { createStore } from 'vuex'
import AuthModule from "./modules/AuthModule";
import PublicFormsModule from "./modules/PublicFormsModule";
import GeneralModule from "./modules/GeneralModule";
import PaginationModule from "./modules/PaginationModule";
import MerchantUsersModule from "./modules/MerchantUsersModule";
import MerchantConfigsModule from "./modules/MerchantConfigsModule";
import MerchantPermissionModule from "./modules/MerchantPermissionModule";
import MerchantDefaultPlayerModule from "./modules/MerchantDefaultPlayerModule";
import MerchantFormsModule from "./modules/MerchantFormsModule";
import MerchantRestaurantsModule from "./modules/MerchantRestaurantsModule";
import MerchantACSystemModule from "./modules/MerchantACSystemModule";
import MerchantACSystemCreateModule from "./modules/MerchantACSystemCreateModule";
import CollectiveModule from "./modules/CollectiveModule";
import ImportModule from "./modules/ImportModule";
import SkidataModule from "./modules/SkidataModule.js";

export default createStore({
    modules:{
        auth: AuthModule,
        publicForms: PublicFormsModule,
        general: GeneralModule,
        pagination: PaginationModule,
        merchantUsers: MerchantUsersModule,
        merchantConfigs: MerchantConfigsModule,
        merchantPermissions: MerchantPermissionModule,
        merchantDplayer: MerchantDefaultPlayerModule,
        merchantForms: MerchantFormsModule,
        merchantRestaurant: MerchantRestaurantsModule,
        merchantAcSystem: MerchantACSystemModule,
        merchantAcSystemCreate: MerchantACSystemCreateModule,
        merchantCollective: CollectiveModule,
        importModule: ImportModule,
        skidata: SkidataModule,
    }
})
