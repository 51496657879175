const getSearchQuery = function(search) {
    let items = {};
    for (let searchKey in search) {
        if(search.hasOwnProperty(searchKey)) {
            if(search[searchKey]) {
                items[searchKey] = search[searchKey];
            }
        }
    }

    const params = new URLSearchParams(items);
    return params.toString();
}

export default {
    getSearchQuery
}
