import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";

export default {
    namespaced: true,
    state: () => ({
        type: null,
        amount: [],
    }),
    mutations: {
        RESET_AMOUNT(state) {
            state.amount = [];
        },
        ADD_AMOUNT(state, value){
            state.amount.push(value)
        },
        SET_TYPE(state, value){
            state.type = value;
        }
    },
    actions: {
        setType({commit}, type) {
            commit('SET_TYPE', type)
        },
        validateToken({dispatch}, token){
            dispatch('general/setActionState', true, {root:true});
            router.post(ROUTES.MERCHANT.RESTAURANT.VALIDATE, {scan : token}, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        },
        onClickNumber({commit, state, getters}, value) {
            if (value === '.') {
                if(getters.isCredit) {
                    return false;
                }

                if (!state.amount.length) {
                    commit('ADD_AMOUNT', 0);
                }

                if(state.amount.indexOf('.') > -1 ) {
                    return false;
                }
            }

            commit('ADD_AMOUNT', value)
        },
        onReset({commit}){
            commit('RESET_AMOUNT')
        },
        onUpdate({dispatch, commit, state, getters}, {id, amount}){
            if(getters.getTotalAmount === '0' && !id && !amount) {
                return;
            }
            let route = ROUTES.MERCHANT.RESTAURANT.UPDATE;
            route = route.replace('@', id);

            dispatch('general/setActionState', true, {root:true});

            let data = {
                amount: amount,
                type: state.type,
                _method: 'put'
            }
            router.put(route, data, {
                onSuccess: () => {
                    commit('RESET_AMOUNT')
                },
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        }
    },
    getters: {
        isCredit: (state) => state.type && state.type === 'credit',
        isMoney: (state) => state.type && state.type === 'amount',
        getTotalAmount(state){
            if(!state.amount.length) {
                return '0';
            }
            return state.amount.join('')
        },
    }
}
