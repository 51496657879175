import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";

export default {
    namespaced: true,
    state: () => ({
        users: []
    }),
    mutations: {
        SET_USERS: (state, value) => state.users = value
    },
    actions: {
        init({dispatch, commit}, users) {
            let data = users?.data || [];
            commit('SET_USERS', data);
            dispatch('pagination/create', users, {root: true});
        },
        destroy({dispatch, commit}){
            commit('SET_USERS', []);
            dispatch('pagination/destroy', [], {root: true});
        },
        createUser({dispatch}, form){
            dispatch('general/setActionState', true, { root:true})
            router.post(ROUTES.MERCHANT.USERS.STORE, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
        updateUser({dispatch}, {form, userId}){
            dispatch('general/setActionState', true, { root:true})

            let route = ROUTES.MERCHANT.USERS.UPDATE;
            route = route.replace('@', userId);

            router.put(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        }
    },
    getters: {
        getUsers: (state) => state.users || [],
        getEditUrl: () => (id) => {
            let route = ROUTES.MERCHANT.USERS.EDIT;
            return route.toString().replace('@', id);
        },
        getCreateUrl: () => ROUTES.MERCHANT.USERS.CREATE
    },
}
