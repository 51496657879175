import moment from "moment/moment";

const capitalLowercase = (value) => {
    let string = value.toString().toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const lowercase = (value) => {
    return value.toString().toLowerCase();
}

const birthdateForDateInput = (value) => {
    if (!value){
        return null;
    }
    return moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
}

const basename = (text) => {
    return text.split('/').reverse()[0];
}
export default {
    capitalLowercase,
    lowercase,
    birthdateForDateInput,
    basename
}
