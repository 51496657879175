import defaultForms from "../../helpers/defaultForms";
import { router } from "@inertiajs/vue3";
import ROUTES from "../../helpers/routes";
import textHelper from "../../helpers/textHelper";

const propsCategory = {
    'ballboy': ['tennisHabitFreq', 'mindate', 'maxdate', 'now'],
    'press': ['days', 'medias'],
    'line': ['periods', 'lineTypes', 'polos', 'shoes', 'pants', 'jackets'],
    'volunteer': ['educations', 'languages', 'levels', 'shifts', 'vlettersize', 'vnumbersize', 'vshoessize'],
    'player': ['tours', 'players', 'guestTypes'],
    'guest': ['guestPlayers', 'defaultGuestTypes'],
    'golf-volunteer': ['geducations', 'glanguages', 'glevels', 'gshifts', 'gavailability', 'gpoloSize', 'gjacketSize', 'gpositions'],
    'golf-press': ['gdays', 'gmedias']
}

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        customProps: {},
        errors: {}
    }),
    mutations: {
        SET_LOADING: (state, value) => state.loading = value,
        SET_CUSOMPROPS: (state, value) => state.customProps = value,
        SET_ERRORS: (state, value) => state.errors = value,
    },
    actions: {
        setCustomProps({commit, getters}, {typeForm = null}) {
            commit('SET_LOADING', true);
            commit('SET_CUSOMPROPS', {});
            commit('SET_ERRORS', {});
            if (!typeForm || Object.keys(propsCategory).indexOf(typeForm) === -1) {
                setTimeout(() => {
                    commit('SET_LOADING', false)
                }, 300);
                return true;
            }

            const only = [...propsCategory[typeForm], 'errors'];
            router.reload({
                only: only,
                onSuccess: (page) => {
                    commit('SET_ERRORS', page.props.errors);
                    let customProps = {};
                    only.forEach(item => {
                        if (page.props.hasOwnProperty(item)) {
                            customProps[item] = page.props[item];
                        }
                    })
                    if (typeForm === 'line') {
                        customProps['types'] = page.props['lineTypes'];
                        delete customProps['lineTypes'];
                    }

                    if (typeForm === 'volunteer') {
                        customProps['poloSize'] = page.props['vlettersize'];
                        customProps['pantSize'] = page.props['vnumbersize'];
                        customProps['jacketSize'] = page.props['vlettersize'];
                        customProps['shoesSize'] = page.props['vshoessize'];
                        delete customProps['vlettersize'];
                        delete customProps['vnumbersize'];
                        delete customProps['vshoessize'];
                    }

                    if (typeForm === 'golf-volunteer') {
                        customProps['educations'] = page.props['geducations'];
                        customProps['languages'] = page.props['glanguages'];
                        customProps['levels'] = page.props['glevels'];
                        customProps['shifts'] = page.props['gshifts'];
                        customProps['availability'] = page.props['gavailability'];
                        customProps['poloSize'] = page.props['gpoloSize'];
                        customProps['jacketSize'] = page.props['gjacketSize'];
                        customProps['positions'] = page.props['gpositions'];
                        delete customProps['geducations'];
                        delete customProps['glanguages'];
                        delete customProps['glevels'];
                        delete customProps['gshifts'];
                        delete customProps['gavailability'];
                        delete customProps['gpoloSize'];
                        delete customProps['gjacketSize'];
                        delete customProps['gpositions'];
                    }

                    if (typeForm === 'golf-press') {
                        customProps['days'] = page.props['gdays'];
                        customProps['medias'] = page.props['gmedias'];
                        delete customProps['gdays'];
                        delete customProps['gmedias'];
                    }

                    if (customProps.hasOwnProperty('errors')) {
                        delete customProps.errors;
                    }
                    commit('SET_CUSOMPROPS', customProps);
                },
                onFinish: () => {
                    setTimeout(() => {
                        commit('SET_LOADING', false)
                    }, 500)
                }
            })
        },
        onCreateNew({commit, state, dispatch}, {data, typeForm}) {
            let only = [];
            if (!typeForm || Object.keys(propsCategory).indexOf(typeForm) === -1) {
                only = [];
            } else {
                only = [...propsCategory[typeForm], 'flash', 'errors'];
            }


            dispatch('general/setActionState', true, {root: true});
            router.post(ROUTES.MERCHANT.SYSTEM.STORE, data, {
                only: only,
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                },
                onError: errors => {
                    commit('SET_ERRORS', errors);
                }
            })
        },
        onUpdateCurrent({commit, state, dispatch}, {data, typeForm, formID}) {
            let only = [];
            if (!typeForm || Object.keys(propsCategory).indexOf(typeForm) === -1) {
                only = [];
            } else {
                only = [...propsCategory[typeForm], 'flash', 'errors'];
            }


            dispatch('general/setActionState', true, {root:true});

            const route = ROUTES.MERCHANT.SYSTEM.UPDATE.replace('@', formID);

            let form = {...data, _method: 'put'}

            router.put(route, form, {
                only: only,
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                },
                onError: errors => {
                    commit('SET_ERRORS', errors);
                }
            })
        },
    },
    getters: {
        getForm: () => (type) => {
            let form = {};
            switch (type.toString().toLowerCase()) {
                case 'ballboy':
                    form = defaultForms.BallboymForm;
                    break;

                case 'player':
                    form = defaultForms.PlayermForm;
                    break;

                case 'press':
                    form = defaultForms.PressmForm;
                    break;

                case 'guest':
                    form = defaultForms.GuestmForm;
                    break;

                case 'line':
                    form = defaultForms.LinemForm;
                    break;

                case 'volunteer':
                    form = defaultForms.VolunteermForm;
                    break;

                case 'accreditation':
                    form = defaultForms.AccreditationmForm;
                    break;

                case 'free':
                    form = defaultForms.FreemForm;
                    break;

                case 'golf-volunteer':
                    form = defaultForms.GolfVolunteerForm;
                    break;

                case 'golf-accreditation':
                    form = defaultForms.GolfAccreditation;
                    break;

                case 'golf-press':
                    form = defaultForms.GolfPress;
                    break;
            }
            return {...form, typeForm: type}
        },
        getEditForm: (state, getters) => (form) => {
            let mForm = {...form};
            mForm.image = getters.serveFile(mForm.image);

            if (mForm.hasOwnProperty('birthDate') && null !== mForm.birthDate) {
                mForm.birthDate = textHelper.birthdateForDateInput(mForm.birthDate)
            }

            if (form?.daily) {
                mForm.datePermission = textHelper.birthdateForDateInput(form.daily.datePermission)
                mForm.endPermission = textHelper.birthdateForDateInput(form.daily.endPermission)
            }

            switch (form.typeForm.toString().toLowerCase()) {
                case 'ballboy':
                    delete mForm['ballboy_detail'];
                    mForm['ballboy'] = form['ballboy_detail'] ?? defaultForms.BallboymForm.ballboy;
                    break;

                case 'player':
                    mForm['has_guest1'] = mForm['has_guest2'] = mForm['has_guest3'] = false;
                    mForm['player'] = form['player_detail'] ?? defaultForms.PlayermForm.player;

                    mForm.player.validity = textHelper.birthdateForDateInput(mForm.player.validity)
                    mForm.player.passportPhoto = getters.serveFile(mForm.player.passportPhoto);


                    mForm.guests = {};
                    if (form['player_group'] && form['player_group'].length) {
                        for (let index in form['player_group']) {
                            let correctIndex = parseInt(index) + 1;
                            let guest = form['player_group'][index];
                            let detail = guest['player_detail'];

                            guest.birthDate = textHelper.birthdateForDateInput(guest.birthDate)
                            detail.validity = textHelper.birthdateForDateInput(detail.validity)
                            guest.image = getters.serveFile(guest.image)

                            mForm.guests[correctIndex] = guest;
                            mForm.guests[correctIndex]['detail'] = detail;
                            mForm[`has_guest` + correctIndex] = true;

                            delete guest['player_detail']
                        }
                        delete mForm['player_group'];
                    } else {
                        mForm['guests'] = {};
                    }

                    delete mForm['player_detail'];
                    break;

                case 'press':
                    delete mForm['press_detail'];
                    mForm['press'] = form['press_detail'] ?? defaultForms.PressmForm.press;
                    mForm['press'].validity = textHelper.birthdateForDateInput(mForm['press'].validity)
                    break;

                case 'guest':
                    mForm['guest'] = form['player_detail'] ?? defaultForms.GuestmForm.guest;
                    mForm.guest.validity = textHelper.birthdateForDateInput(mForm.guest.validity);

                    delete mForm['player_detail'];
                    break;

                case 'line':
                    delete mForm['line_detail'];
                    mForm['line'] = form['line_detail'] ?? defaultForms.LinemForm.line;
                    break;

                case 'volunteer':
                    delete mForm['volunteer_detail'];
                    mForm['volunteer'] = form['volunteer_detail'] ?? defaultForms.VolunteermForm.volunteer;
                    break;

                case 'golf-volunteer':
                    delete mForm['golfvolunteer_detail'];
                    mForm['volunteer'] = form['golfvolunteer_detail'] ?? defaultForms.GolfVolunteerForm.volunteer;
                    break;

                case 'golf-press':
                    delete mForm['golfpress_detail'];
                    mForm['press'] = form['golfpress_detail'] ?? defaultForms.GolfPress.press;
                    mForm['press'].validity = textHelper.birthdateForDateInput(mForm['press'].validity)
                    break;

                case 'accreditation':
                case 'golf-accreditation':
                case 'free':
                    break;
            }

            return mForm;
        },
        serveFile: (state, getters, rootState, rootGetters) => (file) => {
            return rootGetters['merchantForms/serveFile'](file)
        },
        getLoading: (state) => state.loading,
        getCustomProps: (state) => state.customProps || {},
        getErrors: (state) => state.errors || {}
    },
}
