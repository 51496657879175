import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";

export default {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    actions: {
        Login({dispatch, commit}, user) {
            dispatch('general/setActionState', true, {root: true})
            router.post(ROUTES.MERCHANT.AUTH.LOGIN, user, {
                onFinish: (page) => {
                    dispatch('general/setActionState', false, {root: true})
                }
            })
        },
        Logout() {
            router.post(ROUTES.MERCHANT.AUTH.LOGOUT, null, {
                only: ['user', 'navbar']
            })
        }
    },
    getters: {},
}
