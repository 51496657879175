import { router } from '@inertiajs/vue3'
import ROUTES from "../../helpers/routes";
import { Promise } from "es6-promise";

export default {
    namespaced: true,
    actions: {
        massiveUpdatePermission({dispatch}, form) {
            return new Promise((resolve, reject) => {
                dispatch('general/setActionState', true, {root:true});
                router.post(ROUTES.MERCHANT.CONFIG.PERMISSION, form, {
                    onError: (errors) => {
                        reject(errors)
                    },
                    onSuccess: () => {
                        resolve()
                    },
                    onFinish: () => {
                        dispatch('general/setActionState', false, {root:true});
                    }
                })
            })
        },
        massiveUpdateCategory({dispatch}, form) {
            return new Promise((resolve, reject) => {
                dispatch('general/setActionState', true, {root:true});
                router.post(ROUTES.MERCHANT.CONFIG.CATEGORY, form, {
                    onError: (errors) => {
                        reject(errors)
                    },
                    onSuccess: () => {
                        resolve()
                    },
                    onFinish: () => {
                        dispatch('general/setActionState', false, {root:true});
                    }
                })
            })

        },
        assignRestaurants({dispatch}){
            dispatch('general/setActionState', true, {root:true});
            router.post(ROUTES.MERCHANT.CONFIG.ASSIGN, null, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        },
        exportRestauranteLogs({dispatch}, data){
            dispatch('general/setActionState', true, {root:true});
            router.post(ROUTES.MERCHANT.RESTAURANTLOGS.EXPORT, data, {
                onSuccess: () => {
                    dispatch('general/hideModal', false, {root:true});
                },
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        },
        clearCache({dispatch}, types){
            if(!Array.isArray(types) || !types.length){
                return false;
            }
            dispatch('general/setActionState', true, {root:true});
            router.post(ROUTES.MERCHANT.CONFIG.CACHE, {types: types}, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        },
        storeResetCredit({dispatch}, form){
            dispatch('general/setActionState', true, {root:true});
            router.post(ROUTES.MERCHANT.CONFIG.RESETCREDITS.STORE, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        },
        updateResetCredit({dispatch}, {form, id}){
            dispatch('general/setActionState', true, {root:true});
            let route = ROUTES.MERCHANT.CONFIG.RESETCREDITS.UPDATE;
            route = route.replace('@', id);
            router.put(route, form, {
                onFinish: () => {
                    dispatch('general/setActionState', false, {root:true});
                }
            })
        }
    },
    getters: {
        resetCreditBackLink: () => ROUTES.MERCHANT.CONFIG.RESETCREDITS.STORE,
        resetCreditCreateLink: () => ROUTES.MERCHANT.CONFIG.RESETCREDITS.CREATE,
        resetCreditEditLink: () => (id) => {
            let route = ROUTES.MERCHANT.CONFIG.RESETCREDITS.EDIT;
            return route.replace('@', id);
        },
    }
}
