import ROUTES from "../../helpers/routes";
import { router } from "@inertiajs/vue3";

export default {
    namespaced: true,
    state: () => ({
        routes: ROUTES.MERCHANT.IMPORT
    }),
    mutations:{},
    actions:{
        init({dispatch}, data) {
            dispatch('pagination/create', data, {root: true});
        },
        create({dispatch, state}, form){
            if (!state.routes.hasOwnProperty('STORE')) {
                return;
            }
            dispatch('general/setActionState', true, { root:true})
            router.post(state.routes.STORE, form, {
                forceFormData: true,
                onFinish: () => {
                    dispatch('general/setActionState', false, { root:true});
                }
            })
        },
    },
    getters:{
        getDownloadRoute(){
            return (name) => {
                const route = ROUTES.MERCHANT.IMPORT.DOWNLOAD;
                return route + "?file="+encodeURI(name);
            }
        }
    },
}
